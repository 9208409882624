import React from 'react';

function Crosshair(props) {
	const title = props.title || "crosshair";

	return (
		<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="#454545" stroke="#454545" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
		<circle cx="12" cy="12" fill="none" r="9" stroke="#454545"/>
		<circle cx="12" cy="12" fill="none" r="1" stroke="inherit"/>
		<circle cx="12" cy="12" r="1" stroke="none" strokeLinejoin="miter"/>
		<line fill="none" stroke="#454545" x1="12" x2="12" y1="1" y2="5"/>
		<line fill="none" stroke="#454545" x1="23" x2="19" y1="12" y2="12"/>
		<line fill="none" stroke="#454545" x1="12" x2="12" y1="23" y2="19"/>
		<line fill="none" stroke="#454545" x1="1" x2="5" y1="12" y2="12"/>
	</g>
</svg>
	);
};

export default Crosshair;