const layerOptionsDefaults = {
  water: {
    paneName: 'testPaneName',
    displayValues: true,
    displayOptions: {
      velocityType: "Water",
      position: "topright",
      emptyString: "",
      angleConvention: "meteoCW",
      speedUnit: "kt",
    },
    maxVelocity: 1.0,
    velocityScale: 0.18,
    lineWidth: 2,
    particleAge: 5,
    particleMultiplier: 1 / 80,
  },
  air: {
    displayValues: true,
    displayOptions: {
      velocityType: "Wind",
      position: "bottomleft",
      emptyString: "No wind data",
      angleConvention: "meteoCW",
      speedUnit: "kt",
    },
    maxVelocity: 15,
  },
};

export {layerOptionsDefaults};
