import React from 'react';

function Gantt(props) {
	const title = props.title || "gantt";

	return (
		<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="#454545">
		<rect height="2" width="5" fill="#454545" y="3"/>
		<rect height="2" width="11" x="3" y="8"/>
		<rect height="2" width="13" fill="#454545" x="8" y="13"/>
		<rect height="2" width="5" x="19" y="18"/>
	</g>
</svg>
	);
};

export default Gantt;