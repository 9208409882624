import { getFirestore, collection, addDoc, doc, getDoc, setDoc, getDocs, query, where } from "firebase/firestore"

const db = getFirestore();


export const getModelAccess = async () => {
  // get all docs in firebase collection
  const docs = collection(db, "models");
  const docSnap = await getDocs(docs);
  if (docSnap.empty) {
    console.log("No model found");
  } else {
    const modelAccessData = await docSnap.docs.map(doc => doc.data());
    // console.log("modelAccessData", modelAccessData);
    return modelAccessData;
  }
}