import React, { useContext, useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import Play from './icons/Play';
import Pause from './icons/Pause';
import Stop from './icons/Stop';
import NextTrack from './icons/NextTrack';
import PrevTrack from './icons/PrevTrack';
import Direction from './icons/Direction'


import { MapLayersContext } from "./contexts/MapLayers";
import { TimeSelectorContext } from './contexts/TimeSelector';
import { WindSpeedContext } from './contexts/WindSpeed';
import { AppContext } from './contexts/App';
import { LoadingContext } from './contexts/Loading';

const Wrapper = styled.div`
    
    display: grid;
    grid-auto-rows: 48px;
    /* grid-gap: 12px; */

    align-items: center;
    justify-content: center;
    
    padding: 1rem 0rem 1.5rem;
    
    border-radius: 8px 8px 0px 0px;

    z-index: 5;

    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(6px);
    box-shadow: 0px 0px 10px 2px rgba(43,43,43,0.52);

    -webkit-tap-highlight-color: transparent;

    h4 {
        font-size: 14px;
        color: white;
        margin: 0;
        padding: 0;
    }

    & > div {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        min-width: 280px;
        /* max-width: 380px; */

        /* flex-direction: ${props => props.dir ? 'column' : 'row'}; */

        & > div {
            
            svg {
                height: 0.6rem;
                width: 0.6rem;
                margin-right: 0.2rem;
                * {
                    fill: white;
                }
            }
            /* & + div {
                margin-top: ${props => props.dir ? '1.66rem' : '0'};
                margin-bottom: ${props => props.dir ? '.66rem' : '0'};
            } */

        }
    }

    p {
        color: white;
        text-align: center;
        width: 100%;
    }
    label {
        color: white;
        display: block;
        font-size: 0.66rem;
        text-transform: uppercase;
        color: #989898;
        font-weight: 500;
        letter-spacing: 1px;
        /* padding-left: 4px; */
        padding-bottom: 4px;
        /* display: none; */
        /* margin-bottom: 5px; */
    }
    
    & > div > div {
        color: white;
    }

    select,
    option {
        color: white;
        appearance: none;
        border: none;
        background: none;
        outline: none;
        padding: 0rem 0px;
        font-size: 0.9rem;
        font-weight: 400;
        letter-spacing: 1px;
        /* font-family: 'Pragati Narrow', sans-serif; */
        /* text-decoration: underline; */
    }

    p {
        text-align: center;
        color: white;
    }

    select {
        border-radius: 0;
        border-bottom: 1px solid white;
        cursor: pointer;
    }
`

const Action = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* border-radius: 6px; */
    color: white;
    padding: 3px 0px;
    /* background: rgba(255, 255, 255, 0.15); */
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    /* -webkit-backface-visibility: hidden; */
    /* transform: ${props => !props.dir ? "perspective(10px) rotateY(0deg)" : "perspective(10px) rotateY(-0deg)"}; */
    /* transform-origin: 0 50%; */
    /* opacity: 0.66; */
    img {
        width: 48px;
        height: 48px;
    }
    div {
        font-size: 0.9rem;
        letter-spacing: 1px;
    }
    /* opacity: 0; */
    /* svg * {
        fill: white;
    } */
`

const ModelPanel = () => {

    const {day, days, time, times, handleDayChange, handleTimeChange, offsetTimeChange, isActive, toggleAutoPlay} = useContext(TimeSelectorContext);
    const { trackedLayers, trackLayers, selectedModel, selectModel } = useContext(MapLayersContext);


    const handleModelChange = (e) => {
        selectModel(e.target.value)
    }

    const regex = /^[0-9]*/g;
    
    return (
        <Wrapper dir={true}>
            <div>
                <div>
                    <label htmlFor="">Model</label>
                    <select name="" id="" value={selectedModel} onChange={handleModelChange}>
                    {Object.keys(trackedLayers).sort(function(a, b) { return trackedLayers[a].layers.name.match(regex) - trackedLayers[b].layers.name.match(regex); }).map((key) => {
                        return trackedLayers[key].velocityLayer != null ? <option value={key}>{trackedLayers[key].layers.name}</option> : null;
                    })}
                    </select>
                </div>
                {/* <div>
                    <label htmlFor="">Base Map</label>
                    <select name="" id="" value={selectedModel} onChange={handleModelChange}>
                        <option value="">ESRI Satellite</option>
                        <option value="">Stylized Map</option>
                    </select>
                </div> */}
            </div>
        </Wrapper>
    )
}

const shrink = keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
`

const StoryTimer = styled.div`
    position: absolute;
    bottom: 0;
    width: calc(100%);
    height: 6px;
    box-sizing: border-box;
    overflow: hidden;
    &::before {
        animation: ${({shown}) => {return shown ? shrink : 'none'}} 4s linear 1;
        animation-fill-mode: forwards;
        content: '';
        display: block;
        height: 100%;
        width: 0%;
        background: #0273f5;
    }
`

const vibrate = () => {
    navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
    if (navigator.vibrate) {
        navigator.vibrate(33);
    }
}

const TimePanel = () => {


    const { loadingToMap, setLoadingToMap } = useContext(LoadingContext);
    const {day, days, time, times, handleDayChange, handleTimeChange, offsetTimeChange, isActive, toggleAutoPlay} = useContext(TimeSelectorContext);
    const { trackedLayers, trackLayers, selectedModel, selectModel } = useContext(MapLayersContext);

    return (
        <Wrapper dir={true}>
            <div>
                <div>
                    <label htmlFor="">Date</label>
                    <select name="" id="" value={day} onChange={handleDayChange}>
                        {days.map((v) => {
                            return <option value={v}>{v}</option>
                        }) }
                    </select>
                </div>
                <div>
                    <label htmlFor="">Time</label>
                    <select name="" id="" value={time} onChange={handleTimeChange}>
                        {times.map((v) => {
                            return <option value={v}>{v.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' })}</option>
                        }) }
                    </select>
                </div>
            </div>
            <div>
                <Action dir={true} onTouchStart={vibrate} onClick={(e)=>{ offsetTimeChange(e, -1)}}>
                    <div>–1 HR</div>
                    {/* <div><PrevTrack/></div> */}
                    {/* <img src="/Prev.svg" alt="" /> */}
                </Action>
                <Action dir={true} onTouchStart={vibrate} onClick={(e)=>{ setLoadingToMap(false); toggleAutoPlay();}}>
                    <div>{isActive ? <Stop/> : <Play/>}{isActive ? 'Stop' : 'Auto-advance'}</div>
                </Action>
                <Action dir={false} onTouchStart={vibrate} onClick={(e)=>{ offsetTimeChange(e, 1)}}>
                    <div>+1 HR</div>
                    {/* <div><NextTrack/></div> */}
                    {/* <img src="/Next.svg" alt="" /> */}
                </Action>
            </div>
            <StoryTimer shown={isActive && !loadingToMap} onAnimationEnd={()=>{ setLoadingToMap(false); offsetTimeChange(null, 1) }}/>
        </Wrapper>
    )
}

const AnimatedDirectionalArrow = styled.div`
    margin-right: 0.5rem;
    display: inline-block;
    & > svg {
        height: 1rem !important;
        width: 1rem !important;
        transition-duration: 100ms;
        transition-timing-function: ease-out;
        will-change: transform;
        transform: ${props => {
        return `rotate(${props.direction}deg)`
        }};
    }
`
const DirectionalArrow = ({direction}) => {
    return (
        <AnimatedDirectionalArrow direction={direction}>
            <Direction/>
        </AnimatedDirectionalArrow>
    )
}

const MeasurePanel = () => {

    const { cursorData, centerData } = useContext(WindSpeedContext)
    const { isMobile } = useContext(AppContext)

    const [displayedData, setDisplayedData] = useState(isMobile ? centerData : cursorData[0]);

    useEffect(() => {
        setDisplayedData(isMobile ? centerData : cursorData[0]);
    }, [isMobile, cursorData, centerData])

    return (
        <Wrapper>
            {displayedData ? (
                <>
                    <div>
                        <div>
                            <label htmlFor="">Speed</label>
                            <div>{displayedData?.velocity} {displayedData?.speedUnit}</div>
                        </div>
                        <div>
                            <label htmlFor="">Direction</label>
                            <div><DirectionalArrow direction={displayedData?.direction}/>{displayedData?.direction}°</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label htmlFor="">Position</label>
                            <div>{displayedData?.position.lat.toFixed(3)}°N&nbsp;&nbsp;&nbsp;&nbsp;{displayedData?.position.lng.toFixed(3)}°E</div>
                        </div>
                    </div>
                </>
            ) : <p>{isMobile ? 'Center the map over current data.' : 'Move cursor over model data.'}</p> }
        </Wrapper>
    )

}

export {TimePanel, ModelPanel, MeasurePanel};