const mergeUint8Array = (arr1, arr2) => {
    var mergedArray = new Uint8Array(arr1.length + arr2.length);
    mergedArray.set(arr1);
    mergedArray.set(arr2, arr1.length);
    return mergedArray;
}

const Utf8ArrayToStr = (array) => {
    var out, i, len, c;
    var char2, char3;

    out = "";
    len = array.length;
    i = 0;
    while(i < len) {
    c = array[i++];
    switch(c >> 4)
    { 
      case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12: case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(((c & 0x0F) << 12) |
                       ((char2 & 0x3F) << 6) |
                       ((char3 & 0x3F) << 0));
        break;
    }
    }

    return out;
}

async function readStream(stream, callback) {
    
    const reader = stream.getReader();
    let accumulator = new Uint8Array([]);

    // read() returns a promise that resolves when a value has been received
    return reader.read().then(function processText({ done, value }) {

        // Result objects contain two properties:
        // done  - true if the stream has already given you all its data.
        // value - some data. Always undefined when done is true.
        if (done) {
            return accumulator;
        }
    
        accumulator = mergeUint8Array(accumulator, value);

        // Read some more, and call this function again
        return reader.read().then(processText);

        }
    );
}

export { Utf8ArrayToStr, readStream };