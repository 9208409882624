import firebase, { db } from "../contexts/Firebase";
import getStripe from "../util/InitializeStripe";
import { collection, addDoc, setDoc, getDoc, getDocs, DocumentReference, doc, onSnapshot } from "firebase/firestore"; 

export async function createCheckoutSession(uid: string, plan: string) {
  // @ts-ignore
  const firestore = db;
  // Create a new checkout session in the subcollection inside this users document
  const checkoutSessionRef = await addDoc(collection(db, `users/${uid}/checkout_sessions`), {
    price: plan,
    mode: "payment",
    allow_promotion_codes: "true",
    success_url: `${window.location.origin}/?model=${plan}`,
    cancel_url: window.location.origin,
  }).then(async (res) => {
    // get the document at the path res.path from firestore
    onSnapshot(doc(firestore, res.path), async (doc: any) => {
      const { sessionId } = doc.data();
      if (sessionId) {
        const stripe = await getStripe();
        stripe?.redirectToCheckout({
          sessionId,
        });
      }
    });

    // const session = await getDoc(checkoutRef);
    // if (session.exists()) {
    //   console.log(session.data());
    //   const stripeId = session.data().sessionId;
    //   console.log(stripeId);
    //   const stripe = await getStripe();
    //   // @ts-ignore
    //   const { sessionId } = await stripe.redirectToCheckout({
    //     sessionId: stripeId,
    //   });
    // } else {
    //   console.log("session does not exist");
    // }
  });
  
  // const checkoutSessionRef = await firestore.collection("users").doc(uid).collection("checkout_sessions").add({
  //   price: "price_1L6h1wCoKy6L2f6x0ufVuLPC",
  //   mode: "payment",
  //   success_url: window.location.origin,
  //   cancel_url: window.location.origin,
  // });
  // Wait for the CheckoutSession to get attached by the extension
  // set timeout to wait for the session to be attached
  // TODO get this async working without a timeout

  // checkoutSessionRef.onSnapshot(async (snapshot: any) => {
  //   const session = snapshot.data();
  //   if (session) {
  //     const stripe: any = await getStripe();
  //     const result = await stripe.redirectToCheckout({
  //       sessionId: session,
  //     });
  //   }
  // });

  //when checkout session is created alternative to onSnapshot, send to stripe.redirectToCheckout

  // setTimeout(async () => {
  //   const session = await getDoc(checkoutSessionRef);
  //   console.log(session);
  //   const data = session.data();
  //   if (data) {
  //     console.log(data);
  //     const stripeId = data.sessionId;
  //     console.log(stripeId);
  //     const stripe = await getStripe();
  //     // @ts-ignore
  //     const { sessionId } = await stripe.redirectToCheckout({
  //       sessionId: stripeId,
  //     });
  //     return sessionId
  //   }
  // }, 2000);
  // @ts-ignore
  // @ts-ignore
}
