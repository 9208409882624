import React, { useContext, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../hooks/useAuth'
import {
    BrowserRouter as Router,
    useParams
  } from "react-router-dom";
import { AppContext } from '../../contexts/App';

import { SignUp } from './SignUp';
import posthog from 'posthog-js';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Oval } from  'react-loader-spinner'


const Wrapper = styled.div`
    background: rgba(10, 0, 0, 0.3);
    // /* background: white; */
    backdrop-filter: blur(6px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    min-height: calc((var(--vh, 1vh)*100));
    max-height: calc((var(--vh, 1vh)*100));
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
        box-sizing: border-box;
        border-radius: 6px;
        // background: white;
        padding: 3rem;
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: flex;
        justify-content: center;

        @media only screen and (max-width: 768px) {
            width: 100%;
            padding: 1rem;
            height: 100vh;
            border-radius: 0px;
        }
    }
`

const Modal = (props) => {
    const { loading } = useContext(AppContext);
    const { user, isAuthenticated } = useAuth()
    const { showModal, toggleModal } = useContext(AppContext)

    return (
        <Wrapper>
            <div>
                <Oval
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        </Wrapper>
    )
}

export default Modal;