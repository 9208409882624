import React, { useContext, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import Time from './icons/Time'
import Tab_Crosshair from './icons/Tab_Crosshair'
import Tab_Marker from './icons/Tab_Marker'
import Tab_Models from './icons/Tab_Models'


import {TimePanel, ModelPanel, MeasurePanel} from './Scrubber'


export const TabContext = React.createContext();

export const TabProvider = ({ children }) => {

    const [selectedTab, selectTab] = useState(null)

    const _selectTab = (tab) => {
        selectTab(prevTab => (tab == prevTab) ? null : tab )
    }

    const tabs = {
        // Models: {
        //     icon: Tab_Models,
        //     action: null,
        //     component: <ModelPanel/>
        // },
        Time: {
            icon: Time,
            action: null,
            component: <TimePanel/>
        },
        Measure: {
            icon: Tab_Crosshair,
            action: null,
            component: <MeasurePanel/>
        },
        // Markers: {
        //     icon: Tab_Marker,
        //     action: null,
        //     component: null
        // }
    }

    const vibrate = (dur) => {
        navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
        if (navigator.vibrate) {
            navigator.vibrate(dur);
        }
    }

    return (
        <TabContext.Provider value={{tabs, selectedTab, _selectTab, vibrate}}>
            {children}
        </TabContext.Provider>
    )
}

const Wrapper = styled.ul`

    list-style: none;
    padding: 0;
    margin: 0;
    width: auto;
    height: 65px;
    border-radius: ${props => props.rounded ? '8px' : '0px 0px 8px 8px'};
    transition: border-radius 0.33s;
    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(255, 255, 255, 1);
    /* border-top: 1px solid white; */
    /* border-bottom: 1px solid #dbdbdb; */
    
    color: #454545;

    -webkit-tap-highlight-color: transparent;
    user-select: none;

    & > div {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        max-width: 320px;
        & > li {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            cursor: pointer;
            width: 100px;
            & > div {
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 32px;
                justify-content: center;
                align-items: center;
                 & > svg {
                    margin-bottom: 0.5rem;
                }
                & > span {
                    text-transform: uppercase;
                    font-size: 11px;
                    font-weight: 700;
                    letter-spacing: 1px;
                }
            }
        }
    }


`


const Tab = styled.li`
    svg {
        * {
            stroke: ${props=>props.active ? '#0273f5' : '#454545'};;
        }
    }
    color:  ${props=>props.active ? '#0273f5' : '#454545'};
`

const Tabs = () => {

    const { tabs, selectedTab, _selectTab, vibrate } = useContext(TabContext);

    return (
        <Wrapper rounded={selectedTab==null}>
            <div>
                {Object.entries(tabs).map(([key, value], index) => {
                    return (
                        <Tab key={key} active={key==selectedTab} onTouchStart={()=>{vibrate(15)}} onClick={()=>{_selectTab(key)}}>
                            <div>
                                <value.icon/>
                                <span>{key}</span>
                            </div>
                        </Tab>
                    )
                })}
            </div>
        </Wrapper>
    )
}

const appear = keyframes`
    from {
        /* opacity: 0; */
        transform: translateY(100%)
        /* height: 0; */
        /* flex: .00001; */
    }

    to {
        /* opacity: 1; */
        transform: translateY(0%)
        /* height: 85px; */
        /* flex: 1; */
    }
`;


const PanelWrapper = styled.div`

    overflow: hidden;

    & > div {
        /* display: flex; */
        animation: ${appear} 0.33s;
        animation-fill-mode: forwards;
    }

    /* animation-delay: .1s; */
    
    @media only screen and (max-width: 768px) {
        /* top: unset;
        bottom: 0; */

    }

`

export const Panel = () => {

    const { tabs, selectedTab, _selectTab, vibrate } = useContext(TabContext);
    const renderedPanel = tabs[selectedTab]?.component;

    return (
        <PanelWrapper>
            {renderedPanel}
        </PanelWrapper>
    );
}

export default Tabs;