import React, { useState, useEffect } from 'react';

const LoadingContext = React.createContext();

const LoadingProvider = ({ children }) => {

    const [loading, setLoading] = useState(false);
    const [loadingToMap, setLoadingToMap] = useState(false);

    return (
        <LoadingContext.Provider value={{loading, setLoading, loadingToMap, setLoadingToMap}}>
            {children}
        </LoadingContext.Provider>
    )
}

export { LoadingProvider, LoadingContext };