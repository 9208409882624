import React, { useEffect, useState, useRef, useContext } from 'react';
import styled from 'styled-components';

import * as pako from 'pako';
import _, { compact } from 'lodash';

import * as L from 'leaflet'
import { MyLocation } from './Control.MyLocation'; 
import './SmoothWheelZoom.js'
import './NoTileSpaces.js'
import './L.TouchExtend.js'

import { Utf8ArrayToStr, readStream } from './util/Streams'
import { roundTo, roundHours } from './util/Rounding'

import { MapLayersContext } from "./contexts/MapLayers";
import { TimeSelectorContext } from "./contexts/TimeSelector";
import { LoadingContext } from './contexts/Loading';
import { MapInteractionContext } from './contexts/MapInteraction';
import { WindSpeedContext } from './contexts/WindSpeed';
import { AppContext } from './contexts/App';
import { MarkerControl } from './MarkerControl'
import { TabContext } from './Tabs';
import Cursor from './Cursor';
import {Overlay} from './Overlay.js';
import { useAuth } from './hooks/useAuth';

import useVisible, { intersectsMap, intersectionThreshold } from './hooks/useVisible'


import { layerOptionsDefaults } from './LayerOptionsDefaults';

// turf.js imports
import area from '@turf/area';
import intersect from '@turf/intersect';
import booleanIntersects from '@turf/boolean-intersects'
import booleanContains from '@turf/boolean-contains'
import { updateFavorite } from './util/updateFavorite';

const MapContainer = styled.div`
    width: 100%;
    height: 100%;
    background: black;
    z-index: 1;
    canvas {
        pointer-events: none;
    }
    .leaflet-control-velocity {
        color: white;
    }
    .leaflet-control-attribution {
        display: none;
    }
`

const Wrapper = styled.div`
    overflow: hidden;
`

const Map = (props) => {
    const { selectedTime, shownTime, currentUser, modelAccess } = props;
    // Reference to Map mount point (DOM element)
    const mapContainer = useRef();

    const [mapRef, setMap] = useState(null);
    const { user, isAuthenticated } = useAuth();
    const { trackedLayersAlt, trackLayersAlt, trackedLayers, trackLayers, selectedModel, selectModel } = useContext(MapLayersContext);
    const { time } = useContext(TimeSelectorContext);
    const { setLoading, setLoadingToMap } = useContext(LoadingContext);
    const { markersToggled, toggleMarkers, markers, addMarker, removeMarker, setMapBounds, setMapZoom } = useContext(MapInteractionContext);
    const { printSpeed, cursorData, setCenter, centerData } = useContext(WindSpeedContext);
    const { menu, toggleMenu, isMobile } = useContext(AppContext);
    const { tabs, selectedTab, _selectTab, vibrate } = useContext(TabContext);


    const [LayerGroupRef, LayerGroup] = useState(L.layerGroup());

    const clickFeature = (e) => {
        if (e.defaultOptions.access) {
            selectModel(e.feature.properties.model)
            try {
                updateFavorite(user?.uid, e.feature.properties.model);
            } catch (error) {
                console.log(error)
            }
        } else {
            console.log('doesnt contain');
            // TODO: show modal
            return false;
        }
    }

    const generateRandomColor = () => {
        return 
    }

    function getKeyByValue(object, value, lkey) {
        return Object.keys(object).find((key) => {
            return object[key][lkey] === value
        });
    }

    function getKeysByValue(object, value, lkey) {
        return _.filter(Object.keys(object), (key) => {
            return object[key][lkey] === value
        });
    }

    const defaultStyle = {
        // color: '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'),
        color: '#0273f5',
        weight: 1,
        opacity: 0.66,
        fillOpacity: 0.05
    }

    // gold border color
    const premiumStyle = {
        color: '#ffd700',
        weight: 1,
        opacity: 0.66,
        fillOpacity: 0.05
    }

    var highlightStyle = {
        color: '#fff', 
        weight: 1,
        opacity: 0.66,
        fillOpacity: 0,
    }

    var selectedStyle = {
        color: '#fff', 
        weight: 1,
        opacity: 0.66,
        fillOpacity: 1,
    }

    const mouseOverFeature = (e) => {
        e.target.setStyle(highlightStyle);
    }
    const mouseOutFeature = (e, map) => {
        e.target.setStyle(defaultStyle);
    }
    
    function onEachFeatureClosure(map) {
        return function onEachFeature(feature, layer) {
            layer.on("click", () => {
                clickFeature(layer);
            });
            layer.on({
                mouseover: mouseOverFeature.bind(map),
                mouseout: mouseOutFeature.bind(map)
            });
            // click: clickFeature.bind(map),
        }
    }
    
    const createGeoFeature = (data, name) => {
        return {
            "type": "Feature",
            "properties": {
                "model": name,
                "bounds": data
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [[
                    [data.lo1, data.la2],
                    [data.lo2, data.la2],
                    [data.lo2, data.la1],
                    [data.lo1, data.la1],
                    [data.lo1, data.la2]
                ]]
            }
        }
    }
    
    const createGeoLayer = (data, name='', map, selectModel, access) => {
        return L.geoJSON(createGeoFeature(data, name), {
            id: 'model-boundary',
            style: defaultStyle,
            access: access,
            onEachFeature: onEachFeatureClosure(map)
        })
    }

    const getMapBounds = (map) => {
        return {
            la1: map.getBounds()._northEast.lat,
            lo1: map.getBounds()._northEast.lng,
            la2: map.getBounds()._southWest.lat,
            lo2: map.getBounds()._southWest.lng
        }
    }
    
    // Signal to the map (via window) that the map container has changed size
    useEffect(()=>{
        setTimeout(()=>{
            window.dispatchEvent(new Event('resize'));  
            // mapRef && mapRef.invalidateSize();
        }, 500)
    }, [menu])

    // Initialize the map, set up controls and event handlers, and return a reference for use in other parts of the app
    const initMap = () => {

        // Initialize a Mapbox Tile Layer
        const account = 'amitnehra';
        const styleId = 'cksme2y1812ei17t8gxudh9mt';
        const accessToken = 'pk.eyJ1IjoiYW1pdG5laHJhIiwiYSI6ImNpazVueGhpMjAwNmxxNmtzZnNqMWppeXoifQ.OIXXF4AWq6nvOMh9NB_YOg';
        const mapTiles = `https://api.mapbox.com/styles/v1/${account}/${styleId}/tiles/{z}/{x}/{y}?access_token=${accessToken}&fresh=true`;
        const mapboxTileLayer = L.tileLayer(mapTiles, {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            accessToken: 'pk.eyJ1IjoiYW1pdG5laHJhIiwiYSI6ImNpazVueGhpMjAwNmxxNmtzZnNqMWppeXoifQ.OIXXF4AWq6nvOMh9NB_YOg'
        });

        const account2 = 'currentlab';
        const accessToken2 = 'pk.eyJ1IjoiY3VycmVudGxhYiIsImEiOiJja3R1dHJpdmoyM2diMnZxNW9yY2s1M3RlIn0.bMEIcy1YmAXECSZXQgm3Pw';
        const nauticalID = "cktutxisq12n818qq2kb0t0m2";
        const nauticalTileLayer = L.tileLayer(`https://api.mapbox.com/styles/v1/${account2}/${nauticalID}/tiles/{z}/{x}/{y}?access_token=${accessToken2}&fresh=true`, {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            accessToken: 'pk.eyJ1IjoiY3VycmVudGxhYiIsImEiOiJja3R1dHJpdmoyM2diMnZxNW9yY2s1M3RlIn0.bMEIcy1YmAXECSZXQgm3Pw'
        });

        // Initialize an esri Tile Layer
        const esriTileLayer = L.tileLayer(
            "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
            {
                attribution: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, " + "AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
            }
        );
        
        // Initialize the Base Layer Group to add to Layer Control
        const baseLayers = {
            "Stylized Map": mapboxTileLayer,
            "Satellite": esriTileLayer,
            "Map": nauticalTileLayer
        };

        // Initialize the Map
        const map = L.map(mapContainer.current, {
            layers: [esriTileLayer], // default to the esri Tile Layer
            preferCanvas: false,
            scrollWheelZoom: false, // disable original zoom function
            smoothWheelZoom: true,  // enable smooth zoom 
            smoothSensitivity: 1,   // zoom speed. default is 1
            zoomAnimation: true,
            zoomControl: false, // disable the default Zoom Control
            zoomSnap: 0,
            select: true,
            doubleTapDragZoom: true,
            doubleTapDragZoomOptions: {
                reverse: false,
            },
            touchExtend: false
        })
        map.setView([43, -65], 4);

        // const markerGroup = L.layerGroup();

        // const newIcon = new L.icon({
        //     // icon: L.divIcon({ className: "custom icon", html: ReactDOMServer.renderToString( <MyComponent/> ) }),
        //     iconUrl: "/Marker.png",
        //     iconSize: [32, 48],
        //     iconAnchor:   [16, 48], // point of the icon which will correspond to marker's location
        //     popupAnchor:  [0, -48]
        //     // shadowSize:   [50, 64], // size of the shadow
        //     // shadowAnchor: [4, 62],  // the same for the shadow
        // });

        function onMouseMove(e) {
            
            const layers = getKeysByValue(map._layers,"testPaneName", "_paneName");
            const data = layers?.map((layer) => {
                return map?._layers[layer]?._mouseControl?.options.getStats(e.latlng);
            })
            printSpeed(data);

        }

        const throttledUpdate = _.throttle(onMouseMove, 100, { 'trailing': false });
        
        if (map) map.on('mousemove', throttledUpdate);

        // Initialize Zoom Control
        const zoomControl = new L.Control.Zoom({ position: 'topleft' })
        zoomControl.addTo(map);

        // Initialize Layer Control
        const layerControl = L.control.layers(baseLayers, null, { position: 'topright', sortLayers: true })
        layerControl.addTo(map);

        // Initialize GPS Control
        new MyLocation({position: 'topleft'}).addTo(map);

        map.on('dragend zoomend', ()=>{
            setMapBounds(getMapBounds(map));
            setMapZoom(map.getZoom().toFixed(2))
        })

        // Return the Map later use
        return map;
    }

    async function fetchLayerData(layer, datetime) {
        
        // Compensate for variations in model timestep
        let time = datetime;
        if (layer.timestep == '3h') {
            time = roundHours(datetime, 3);
        }

        // Construct the filepath based on the selected model and timestep
        const base = 'https://storage.googleapis.com/currentmap-bucket01';
        const dataUrl = `${base}/${layer.dataPrefix.replace('data/', '')}${time.toISOString().slice(0, 13).replace("T", "_")}.00.json.gz`;

        // Extra guard to prevent the user from selecting a model or timestep outside the forecast limit
        if (`${time.toISOString().slice(0, 13).replace("T", "_")}.00` > layer.forecastLimit) { return null; } else {
          
            setLoading(true);
            setLoadingToMap(true);
            
            // GZIP compressed .json.gz file support
            return await fetch(dataUrl).then((response) => {
                return readStream(response.body).then((res) => {
                    try {
                        const binData = pako.inflate(res);
                        setLoading(false);
                        return JSON.parse(Utf8ArrayToStr(binData));
                    } catch (err) {
                        console.log(err);
                    }
                });
            });

            // // Deprecated uncompressed .json file support
            // const response = await fetch(dataUrl).then((response) => {
            //     setLoading(false);
            //     return response;
            // });
            // const data = response.json();
            // return data;
        }
        
    }

    async function initializeLayer(layer, datetime) {
        // const date = new Date();
        const data = await fetchLayerData(layer.metadata, datetime);
        if (data != null) {
            const velocityLayer = L.velocityLayer({
                ...layerOptionsDefaults[layer.metadata.medium],
                ...(layer.metadata.layerOptions || {}),
                name: layer.metadata.name,
                data,
            });
            return velocityLayer;
        } else {
            return null;
        }
    }

    async function getConfiguration() {
        const response = await fetch('https://storage.googleapis.com/currentmap-bucket01/CONFIG/config.json').then((response) => {
            return response;
        });
        const configuration = await response.json();
        return configuration;
    }

    const drawBounds = () => {

        if (mapRef) {
            getConfiguration().then((configuration) => {
                const lay = Object.keys(configuration).map((key) => {
                    return {
                        ...configuration[key],
                        layers: {
                            ...configuration[key].layers,
                            model: key
                        }
                    }
                })
                .reduce((sorted, el) => {
                    let index = 0;
                    while(index < sorted.length && booleanIntersects(createGeoFeature(el.layers.bounds),createGeoFeature(sorted[index].layers.bounds))) {
                        index++;
                    }
                    sorted.splice(index, 0, el);
                    return sorted;
                }, [])
                .reduce((sorted, el) => {
                    let index = 0;
                    while(index < sorted.length && !booleanContains(createGeoFeature(el.layers.bounds),createGeoFeature(sorted[index].layers.bounds))) {
                        index++;
                    }
                    sorted.splice(index, 0, el);
                    return sorted;
                }, [])
                .map((model) => {

                    let t = time;
                    if (model.timestep == '3h') {
                        t = roundHours(time, 3);
                    }
                    const expired = `${t.toISOString().slice(0, 13).replace("T", "_")}.00` > model.forecastLimit;


                    const modelType = modelAccess[modelAccess.map((m) => m.model).indexOf(model.layers.model)].modelType;
                    const access = (currentUser.models?.includes(model.layers.model) || modelType === 'free');
                    const purchased = (currentUser.models?.includes(model.layers.model));
                    const geolayer = createGeoLayer(model.layers.bounds, model.layers.model, mapRef, selectModel, access);
                    
                    if (!mapRef.hasLayer(geolayer) && !expired && (modelType !== 'private' || access)) mapRef.addLayer(geolayer);
                    if (modelType !== 'private' || access) {
                        return {
                            ...model,
                            access: access,
                            purchased: purchased,
                            expired: expired,
                            price: modelAccess[modelAccess.map((m) => m.model).indexOf(model.layers.model)]?.price
                        }
                    } else {
                        return null;
                    }
                }).filter((model) => model != null);
        
                trackLayersAlt(lay);
                setMapBounds(getMapBounds(mapRef));
    
            })
        }

    }

    // Function: Initialize Models
    const initModels = () => {
        getConfiguration().then((configuration) => {
            for (const [model, metaData] of Object.entries(configuration)) {
                initializeLayer({metadata: {...metaData.layers, model: model}, forecastLimit: metaData.forecastLimit}).then((data)=>{
                    trackLayers(prevState => {
                        return {
                            ...prevState,
                            [model]: {
                                enabled: model == selectedModel,
                                geoLayer: createGeoLayer(metaData.layers.bounds, model, mapRef, selectModel),
                                velocityLayer: data,
                                ...metaData
                            }
                        }
                    })
                });
            }
        })
    }

    const updateBounds = () => {
    
        for (const [model, metaData] of Object.entries(trackedLayers)) {
            // if (!mapRef.hasLayer(metaData.geoLayer)) mapRef.addLayer(metaData.geoLayer)
            if (metaData.enabled && !mapRef.hasLayer(metaData.velocityLayer)) {
                mapRef.addLayer(metaData.velocityLayer);

                const bounds = metaData.layers.bounds;
                mapRef.fitBounds([
                    [bounds.la2, bounds.lo1],
                    [bounds.la1, bounds.lo2]
                ], {padding: [50, 100]});

            } else if (!metaData.enabled && mapRef.hasLayer(metaData.velocityLayer)) {
                mapRef.removeLayer(metaData.velocityLayer)
            }
        }
    }

    const updateLayers = () => {
        for (const [model, metaData] of Object.entries(trackedLayers)) {
            trackLayers(prevState => {
                return {
                    ...prevState,
                    [model]: {
                        ...prevState[model],
                        enabled: model == selectedModel,
                    }
                }
            })
        }
    }

    // Perform once: Initialize the map
    useEffect(()=>{
        setMap(initMap());
    }, []);

    // Perform once the map is instantiated:
    // (1) Load the configuration file
    // (2) Draw the model boundaries to the map
    useEffect(()=>{
        if (mapRef && currentUser && modelAccess) {
            drawBounds();
        }
    }, [mapRef, currentUser, modelAccess]);

    // Update displayed model data when the following change:
    // (1) The map has beeen instantiated
    // (2) The configuration file has been loaded
    // (3) The user selects a new model
    // (4) The user selects a new timestep
    useEffect(()=>{

        // Retrieve the configuration of the selected model
        const metaData = _.find(trackedLayersAlt, ['layers.model', selectedModel])

        // Confirm that the model configuration has been loaded and map has been instantiated
        if (trackedLayersAlt.length && mapRef) {

            // Close the hamburger menu if the user is on a mobile device;
            window.matchMedia("(max-width: 768px)").matches && toggleMenu(false);

            // Initialize the selected model (fetch the .json.gz that corresponds to the selected model and selected timestep)
            initializeLayer({metadata: metaData.layers, forecastLimit: metaData.forecastLimit}, selectedTime).then((data)=>{
                
                // Iterate over the stored references to actively displayed model(s) and remove it/them
                LayerGroupRef.eachLayer(function (layer) {
                    LayerGroupRef.removeLayer(layer);
                });
                
                // Store a reference to the model layer being added
                LayerGroupRef.addLayer(data);

                // Add the active model reference layer to the map
                mapRef.addLayer(LayerGroupRef);

                // Only reposition the map to the model if the selected model takes up less than 10% of the screen.
                // This accounts for models that are
                // (1) entirely out of view
                // (2) visible, but only at the edge of the screen
                // (3) entirely within view but very small due to the map being zoomed out
                if (intersectionThreshold(metaData, getMapBounds(mapRef), 0.1, false)) {
                    const bounds = metaData.layers.bounds;
                    mapRef.fitBounds([
                        [bounds.la2, bounds.lo1],
                        [bounds.la1, bounds.lo2]
                    ], {padding: [50, 100]});
                }

            })
        }

    }, [mapRef, trackedLayersAlt, selectedModel, selectedTime]);

    return (
        <>
            {!isMobile && selectedTab=='Measure' ? <Cursor/> : null}
            <Overlay/>
            <MapContainer ref={mapContainer}></MapContainer>
        </>
    )
    
}

export default Map;
