import React, { useState, useEffect } from 'react';

const WindSpeedContext = React.createContext();

const WindSpeedProvider = ({ children }) => {

    const [cursorData, setCursorData] = useState(null)
    const [centerData, setCenterData] = useState(null)

    // var coeff = 1000 * 60 * 60;
    // const date = new Date;
    // var options = { weekday: 'short', month: 'numeric', day: 'numeric' };
    
    // const dates = Array.from({length: 72}, (_, i) => {
    //     return new Date(Math.floor(date.getTime() / coeff) * coeff).addHours(i)
    // });
    
    // const grouped = _.groupBy(dates, (v, i) => {
    //     return v.toLocaleDateString('en-US', options)
    // });

    // const [day, selectDay] = useState(dates[0].toLocaleDateString('en-US', options))
    // const [days, selectDays] = useState(_.keys(grouped));
    // const [times, selectTimes] = useState(grouped[day]);
    // const [time, selectTime] = useState(times[0])

    // const handleDayChange = (event) => {
    //     selectDay(event.target.value);
    // }

    // const handleTimeChange = (event) => {
    //     selectTime(event.target.value);
    // }

    // useEffect(()=>{
    //     selectTimes(grouped[day])
    // }, [day])

    // useEffect(()=>{
    //     selectTime(times[0])
    // }, [times])

    const printSpeed = (data) => {
        setCursorData(data)
    }

    const setCenter = (data) => {
        setCenterData(data)
    }

    return (
        <WindSpeedContext.Provider value={{printSpeed, cursorData, setCenter, centerData}}>
            {children}
        </WindSpeedContext.Provider>
    )
}

export { WindSpeedProvider, WindSpeedContext };