import React from 'react';

function CCheck(props) {
	const title = props.title || "c check";

	return (
		<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="#454545" stroke="#454545" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
		<polyline fill="none" points=" 6,12 10,16 18,8 "/>
		<circle cx="12" cy="12" fill="none" r="11" stroke="#454545"/>
	</g>
</svg>
	);
};

export default CCheck;