import React, { useContext, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

const load = keyframes`
    0% {
        left:0%;
        right:100%;
        width:0%;
    }
    25% {
        left:0%;
        right:75%;
        width:33%;
    }
    50% {
        width:50%;
    }
    75% {
        right:0%;
        left:75%;
        width:33%;
    }
    100% {
        left:100%;
        right:0%;
        width:0%;
    }
`
const appear = keyframes`
    0% {
        /* height: 0px; */
        transform: scaleY(0);
        opacity: 0;
    }
    100% {
        /* height: 4px; */
        transform: scaleY(1);
        opacity: 1;
    }
`



const Wrapper = styled.div`
    height: 6px;
    background: #0273f5;
    position: absolute;
    width: 100%;
    bottom: 0;
    overflow: hidden;
    transform-origin: 50% 100%;
    animation: ${appear} 0.33s linear;
    & > div {
        position:absolute;
        top:0;
        right:100%;
        bottom:0;
        left:0;
        background: linear-gradient(90deg, rgba(2,115,245,0) 0%, rgba(138,56,249,1) 50%, rgba(2,115,245,0) 100%);
        width:0;
        animation: ${load} 2s linear infinite;
    }
`

export const Loader = () => {
    return (
        <Wrapper>
            <div></div>
        </Wrapper>
    )
}