import React from 'react';

function GridView(props) {
	const title = props.title || "grid view";

	return (
		<svg height="16" width="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="#454545">
		<rect height="4" width="4" fill="#454545"/>
		<rect height="4" width="4" fill="#454545" x="6"/>
		<rect height="4" width="4" fill="#454545" x="12"/>
		<rect height="4" width="4" fill="#454545" y="6"/>
		<rect height="4" width="4" fill="#454545" x="6" y="6"/>
		<rect height="4" width="4" fill="#454545" x="12" y="6"/>
		<rect height="4" width="4" fill="#454545" y="12"/>
		<rect height="4" width="4" fill="#454545" x="6" y="12"/>
		<rect height="4" width="4" fill="#454545" x="12" y="12"/>
	</g>
</svg>
	);
};

export default GridView;