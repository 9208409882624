import React, { useContext, useState, useEffect, useRef } from "react";
import useMousePosition from "./hooks/useMousePosition";
import { MouseContext } from "./contexts/Mouse";
import { WindSpeedContext } from './contexts/WindSpeed';
import { AppContext } from './contexts/App';

import Crosshair from './icons/Crosshair'
import Direction from './icons/Direction'

import styled from 'styled-components';

import * as _ from "lodash";

const Wrapper = styled.div`
  width: auto;
  height: auto;

  position: absolute;
  transform: translate(-100%, -100%);
  transition-duration: 100ms;
  transition-timing-function: ease-out;
  will-change: width, height, transform, border;
  z-index: 999;
  pointer-events: none;
  z-index: 10;

  padding: 0.5rem;
  background: rgba(0,0,0,0.66);
  backdrop-filter: blur(6px);
  box-shadow: 0px 0px 10px 2px rgba(43,43,43,0.52);
  color: white;
  border-radius: 8px;
  font-size: 12px;
  & > div > div {
    padding: 0.2rem 0rem;
  }
  & > div > div > svg {
    height: 10px;
    width: 10px;
    margin-right: 0.5rem;
    display: inline-block;
    transition-duration: 100ms;
    transition-timing-function: ease-out;
    will-change: transform;
    transform: ${props => {
      return `rotate(${props.direction}deg)`
    } };
  }

  &::after {
    /* content: ''; */
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-top: 20px solid rgba(0,0,0,0.5);
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    backdrop-filter: blur(6px);
  }

  & > svg {
    height: 32px;
    width: 32px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 32px));
    filter: drop-shadow(2px 2px 6px rgba(0,0,0,0.66))  drop-shadow(-2px -2px 6px rgba(0,0,0,0.66));
    & * {
      stroke-width: 1;
    }
  }
  
`

const SimpleWrapper = styled.div`
    position: absolute;
    transition-duration: 100ms;
    transition-timing-function: ease-out;
    will-change: width, height, transform, border;
    z-index: 999;
    pointer-events: none;
    z-index: 10;

  & > svg {
    height: 32px;
    width: 32px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 32px));
    filter: drop-shadow(2px 2px 6px rgba(0,0,0,0.66))  drop-shadow(-2px -2px 6px rgba(0,0,0,0.66));
    & * {
      stroke-width: 1;
    }
  }
`

const DotRing = () => {
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);
  const { x, y } = useMousePosition();
  const { cursorData } = useContext(WindSpeedContext)
  const { menu } = useContext(AppContext)
    
  const [data, setData] = useState(null);

  const throttledUpdate = useRef(_.throttle(setData, 100));
  
  useEffect(()=>{
    cursorData && cursorData[0] ? throttledUpdate.current(cursorData[0]) : throttledUpdate.current(null);
  }, [cursorData])

  return (
    // <Wrapper direction={data?.direction || 0} style={{ transform: `translate3d(calc(${x - (menu ? 320 : 0)}px - 50%), calc(${y}px - 56px - 100% - 32px), 0)` }} className={"ring " + cursorType} >
    //   {/* {data ? (
    //     <div>
    //       <div><strong>Speed:</strong> {data.velocity} {data.speedUnit}</div>
    //       <div><strong>Direction:</strong> <Direction/>{data.direction}°</div>
    //       <div><strong>Coordinates:</strong> {data.position.lat.toFixed(3)}°N, {data.position.lng.toFixed(3)}°E</div>
    //     </div>
    //   ) : <div>Move cursor over model data.</div>} */}
    //   <Crosshair/>
    // </Wrapper>
    <SimpleWrapper style={{ transform: `translate3d(calc(${x - (menu ? 320 : 0)}px - 50%), calc(${y}px - 56px - 100% - 32px), 0)` }}>
      <Crosshair/>
    </SimpleWrapper>
  );
};

export default DotRing;
