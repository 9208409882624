import React from 'react';

function Unlink(props) {
	const title = props.title || "unlink";

	return (
		<svg height="12" width="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <title>{title}</title>
            <g fill="#454545">
                <path d="M6.1,8.572,4.7,9.935A1.875,1.875,0,1,1,2.056,7.276l1.355-1.39L2.35,4.825.988,6.222A3.375,3.375,0,0,0,5.755,11l1.4-1.369Z" fill="#454545"/>
                <path d="M4.224,6.7l-.754.754A.75.75,0,1,0,4.53,8.514l.755-.755Z"/>
                <path d="M8.572,6.1,9.935,4.7A1.875,1.875,0,1,0,7.276,2.056L5.886,3.411,4.825,2.35,6.222.988A3.375,3.375,0,0,1,11,5.755l-1.369,1.4Z" fill="#454545"/>
                <path d="M6.7,4.224l.754-.754A.75.75,0,1,1,8.514,4.53l-.755.755Z"/>
                <path d="M11,11.75a.744.744,0,0,1-.53-.22l-10-10A.75.75,0,0,1,1.53.47l10,10A.75.75,0,0,1,11,11.75Z"/>
            </g>
        </svg>
	);
};

export default Unlink;