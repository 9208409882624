import React, { useState, useEffect } from 'react';

const MapInteractionContext = React.createContext();

const MapInteractionProvider = ({ children }) => {

    const [mapBounds, setMapBounds] = useState(null);
    const [mapZoom, setMapZoom] = useState(null);
    const [markersToggled, toggleMarkers] = useState(false);
    const [markers, setMarkers] = useState({});

    const addMarker = (marker) => {
        setMarkers((old) => {
            return {
                ...old,
                [marker.id]: marker
            };
        })
    }

    const removeMarker = (id) => {
        setMarkers((old) => {
            const temp = old;
            delete temp.id;
            return temp;
        })
    }

    return (
        <MapInteractionContext.Provider value={{markersToggled, toggleMarkers, markers, addMarker, removeMarker, mapBounds, setMapBounds, mapZoom, setMapZoom}}>
            {children}
        </MapInteractionContext.Provider>
    )
}

export { MapInteractionProvider, MapInteractionContext };