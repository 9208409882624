import React from 'react';

function IRemove(props) {
	const title = props.title || "i remove";

	return (
		<svg width="17" height="31" viewBox="0 0 17 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="#FFF" d="M7.25658 0.418826L0.185514 10.3183C0.0602068 10.4942 -0.00478036 10.706 0.00027401 10.9219C0.00532838 11.1378 0.0801527 11.3463 0.213554 11.5162C0.346956 11.686 0.531764 11.8081 0.740334 11.8642C0.948905 11.9203 1.17003 11.9073 1.37063 11.8273L7.07132 9.54757L7.07061 29.2836C7.07517 29.5458 7.18251 29.7957 7.36953 29.9795C7.55654 30.1633 7.80825 30.2663 8.07046 30.2663C8.33267 30.2663 8.58439 30.1633 8.7714 29.9795C8.95841 29.7957 9.06575 29.5458 9.07031 29.2836L9.07102 9.54757L14.771 11.8266C14.9716 11.9066 15.1927 11.9196 15.4013 11.8635C15.6099 11.8074 15.7947 11.6853 15.9281 11.5155C16.0615 11.3456 16.1363 11.1371 16.1414 10.9212C16.1464 10.7053 16.0814 10.4935 15.9561 10.3176L8.88505 0.418119C8.79246 0.288715 8.67034 0.183272 8.52881 0.110552C8.38729 0.0378306 8.23045 -6.90527e-05 8.07134 9.44484e-08C7.91223 6.92416e-05 7.75543 0.0381049 7.61397 0.110949C7.47251 0.183793 7.35047 0.289341 7.258 0.418825L7.25658 0.418826Z"/>
        </svg>
	);
};

export default IRemove;