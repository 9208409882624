import React, { useEffect, useState, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { TimeSelectorContext } from './contexts/TimeSelector';
import { LoadingContext } from './contexts/Loading';
import { TabContext } from './Tabs';
import { AppContext } from './contexts/App';
import { MapLayersContext } from './contexts/MapLayers';
import { getDocument } from './contexts/Firebase';
import { useAuth } from './hooks/useAuth';
import ReactVelocity from './Velocity'
import {Loader} from './Loader';
import { LayerToggle } from './LayerToggle'
import Modal from './components/modal/Modal'
import Upgraded from './components/modal/Upgraded'
import MenuOpen from './icons/MenuOpen';
import Crosshair from './icons/Crosshair';
import _ from 'lodash'
import Logo from './img/current_lab.svg'
import Map from './MapPOC'
import User from './User';
import { getModelAccess } from './contexts/getModelAccess';
import { Box, flexbox, Spinner } from '@chakra-ui/react'
import ModelPurchase from './components/modal/ModelPurchase';
import { getProducts } from './contexts/getProducts';

const Reticule = () => {
    const { tabs, selectedTab, _selectTab, vibrate } = useContext(TabContext);
    const { isMobile } = useContext(AppContext)
    return selectedTab == 'Measure' && isMobile ? <Crosshair/> : null;
}

const Wrapper = styled.div`

    ${props => props.loading && `
        * {
            cursor: wait !important;
        }
    `}
    
    textarea {
        width: 100%;
        height: 0px;
        opacity: 0;
        display: block;
        resize: none;
        padding: 0;
        border: none;
    }

    width: 100%;
    max-width: 100vw;
    max-heigh: 100vh;
    height: calc((var(--vh, 1vh)*100));
    display: flex;
    overflow: hidden;

    transition: all 0.3s;
    /* grid-template-columns: ${props => props.menu ? '320px calc(100vw - 320px)' : '0px calc(100vw - 0px)'}; */
    & > div {
        transition: all 0.5s;
    }
    & > div:nth-child(1){
        -webkit-overflow-scrolling: touch;
        width: ${props => props.menu ? '320px' : '0px'};
        padding: ${props => props.menu ? '0rem 0rem' : '0'};
        /* transform: ${props => props.menu ? 'translateX(0%)' : 'translateX(-100%)'}; */
        opacity: ${props => props.menu ? '1' : '0'};
        /* transition: ${props => props.menu ? 'opacity 1s' : 'none'}; */
        /* animation-delay: ${props => props.menu ? '1s' : 'none'}; */
    }
    & > div:nth-child(2){
        width: ${props => props.menu ? 'calc(100vw - 320px)' : '100vw'};
        /* transition: ${props => props.menu ? 'width 1s' : 'none'}; */
        /* animation-delay: ${props => props.menu ? '1s' : 'none'}; */
        /* padding-right: ${props => props.menu ? '320px' : '0'}; */
    }

`
const Nav = styled.nav`

    display: grid;
    grid-template-rows: 1fr auto;
    position: relative;

    & > div {
        padding: 0 0.5rem;
        /* border-bottom: 1px solid #dbdbdb; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* grid-gap: 1rem; */
        /* grid-template-columns: calc(20% - 1rem) 60% calc(20% - 1rem); */
        font-size: 13px;
        font-weight: 600;
        & > div:first-child,
        & > div:last-child {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 42px;
            width: 42px;
        }
        & > div:first-child {
            text-align: left;
            svg {
                cursor: pointer;
            }
        }
        & > div:last-child {
            text-align: right;
        }
        & > div:nth-child(2) {
            text-align: center;
        }
        img {
            height: 24px;
            /* width: 42px; */
        }
    }
    /* justify-content: space-between; */
    /* padding: 0rem 1rem; */
    /* & > div:nth-child(1) {
        display: flex;
        align-items: center;
    } */
`
const Main = styled.div`
    display: grid;
    box-sizing: border-box;

    grid-template-rows: 56px calc((var(--vh, 1vh)*100) - 56px);
    /* grid-template-rows: 56px 65px calc((var(--vh, 1vh)*100) - 56px - 65px);
    & > ul:nth-child(3) {
        grid-row: 2;
    }
    @media only screen and (max-width: 768px) {
        grid-template-rows: 56px calc((var(--vh, 1vh)*100) - 56px - 65px) 65px;
        & > ul:nth-child(3) {
            grid-row: 3;
        }
    } */
`

const Column = styled.div`
    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto auto;
    
    border-right: ${props => props.menu ? '1px solid #dbdbdb' : '0px solid #dbdbdb'};

    box-sizing: border-box;
    
    p {
        font-size: 14px;
        line-height: 2;
        a {
            /* color: #28B8A3; */
            /* color: #0273f5; */
        }
    }

    & > ul {
        width: 320px;
        /* margin: 0;
        padding: 0rem; */
        list-style: none;
        height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        ::-webkit-scrollbar {
            width: 0px;
        }
    }
`

const Member = styled.li`
    /* border-right: 1px solid #dbdbdb; */
    cursor: pointer;
    & + & {
        /* border-top: 1px solid #dbdbdb; */
    }
    display: flex;
    align-items: center;
    padding: 1rem;

    &:first-of-type {
        padding-top: 2rem;
    }
    &:last-of-type {
        padding-bottom: 2rem;
    }

    & > div:nth-child(2) {
        display: none;
        width: 48px;
        height: 48px;
        margin-right: 1rem;
        border-radius: 48px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            /* filter: grayscale(100%) brightness(120%);; */
        }
    }
    /* border-top: 1px solid #dbdbdb; */
    /* border-bottom: 1px solid #dbdbdb; */
    ${props => props.active && `
        background: white;
        color: #0273f5;
    `}
    /* background: ${props => props.active ? 'white' : ''}; */
    img {
        filter: ${props => props.read && !props.active ? 'saturate(0%)' : ''};
    }
    transition: all 0.33s;
    opacity: ${props =>  props.read && !props.active ? '0.33' : '1'};
    & > div {
    }
    &::before {
        /* content: ''; */
        /* display: block; */
        height: ${props => props.active ? '4px' : '4px'};
        width: ${props => props.active ? '4px' : '4px'};
        border-radius: 4px;
        background: ${props => props.read ? 'white' : '#28B8A3'};
        border: 2px solid ${props => props.read ? '#757575' : '#28B8A3'};
        margin-right: 0.5rem;
        transition: all 0.33s;
    }
    
    svg {
        height: 14px;
        width: 14px;
        margin-right: 1rem;
    }

    h2 {
        white-space: nowrap;
        font-size: 1rem;
        margin: 0;
        padding: 0;
    }
    p {
        white-space: nowrap;
        /* color: #545454; */
        font-size: 0.8rem;
        margin: 0.1rem 0rem 0rem;
        padding: 0;
    }
`

const rotate = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;


const Content = styled.div`
    /* background: #fafafa; */
    overflow: hidden;
    display: flex;
    /* opacity: 0; */
    /* transform: translateY(150px); */
    animation-fill-mode: forwards;
    animation: ${rotate} 0.5s ease-in;
    /* animation-delay: 0.33s; */
    justify-content: center;
    /* max-width: 420px; */
    
    /* padding: 0 calc((100% - 480px) / 2); */

    /* padding: ${props => props.menu ? '0rem calc((100vw - 480px - 320px) / 2) 0 calc((100vw - 480px - 320px) / 2)' : '0rem calc((100vw - 480px) / 2)'}; */
    /* @media only screen and (max-width: 768px) {
        padding: ${props => props.menu ? '0rem calc((100vw - 480px - 320px) / 2)' : '0rem calc((100vw - 480px) / 2)'};
    } */
    & > div {
        position: relative;
        width: 100%;
        overflow: auto;
        ::-webkit-scrollbar {
            width: 0px;
        }
        
        & > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            transform: translate(-50%, -50%);
        }
        /* padding: 0 clamp(1rem, calc((100% - 480px) / 2), 3000px) 3rem; */
        
    }

    p {
        margin: 1rem 0rem;
        font-size: 14px;
        line-height: 2;
        font-weight: 400;
        width: 100%;
    }

    b {
        font-weight: 500;
    }

    a {
        /* color: #28B8A3; */
        /* color: #0273f5; */
        font-weight: 500;
    }

    @media only screen and (max-width: 768px) {
        /* opacity: ${props => props.menu ? 0.5 : 1}; */
        width: 100vw !important;
    }

`


const Claims = styled.div`
    
    padding: 2rem 0rem 1rem;
    /* border-radius: 12px; */
    /* background: #fafafa; */
    border-top: 1px solid #dbdbdb;
    ul {
        margin: 1.5rem 0rem;
        padding: 0;
        list-style: none;
        /* border-top: 1px solid #dbdbdb; */
        /* border-bottom: 1px solid #dbdbdb; */
    }
    h2 {
        margin: 0;
        font-size: 1rem;
        font-weight: 400;
        color: #757575;
    }
    p {
        font-size: 14x;
        margin: 1rem 0rem;
        line-height: 2;
    }
     li {
        /* display: inline-block; */
        display: grid;
        align-items: flex-start;
        align-items: center;
        grid-template-columns: 1rem auto;
        cursor: pointer;
        grid-gap: 0.5rem;
        padding: 1rem 0rem;
        /* &:hover {
            text-decoration: underline;
        } */
        &:active {
            text-decoration: none;
        }
         /* padding: 9px 12px; */
        font-size: 14px;
        /* text-shadow: 1px 0px rgba(0,0,0,0.4); */
        font-weight: 400;
        /* border-left: 3px solid #28b8a3; */
        /* padding-left: 0.5rem; */
        
        /* font-style: italic; */
        /* text-decoration: underline #28B8A3; */
        /* border-radius: 6px; */
        /* color: white; */
        /* padding: 0.33rem 0.66rem 0.5rem; */
         /* letter-spacing: 1px; */
         /* border: 1px solid #dbdbdb; */
         /* border-radius: 8px; */
         & + li {
             border-top: 1px solid #dbdbdb;
         }
         & > svg {
             /* display: none; */
            /* & > *  {
                fill: white;
                & > *  {
                    fill: white;
                }
            } */
            height: 1rem;
            width: 1rem;
            /* margin-top: 0.15rem; */
         }
     }

`

const Actions = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 2rem;
`

const Button = styled.div`
    cursor: ${props => props.active ? 'pointer' : 'not-allowed'};
    padding: 1rem 2rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    background: ${props => props.active ? '#28B8A3' : 'gray'};
    /* &:hover {
        background: ${props => props.active ? '#3bbda9' : 'gray'};
    } */
    &:active {
        background: ${props => props.active ? '#21A794' : 'gray'};
    }
`

const Avatar = styled.div`
    display: inline-block;
    height: 96px;
    width: 96px;
    overflow: hidden;
    border-radius: 96px;
    margin-bottom: 1rem;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`

const Header = styled.div`
    text-align: center;
    h1, h2 {
        margin: 0;
    }
    h1 {
        font-size: 1.66rem;
        font-weight: 600;
    }
    h2 {
        margin-top: 0.33rem;
        font-size: 1rem;
        font-weight: 400;
        color: #757575;
    }
`

const Bio = styled.div`
    padding: 2rem 0rem;
    p {
        text-align: center;
    }
`


const Projects = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0rem 0rem;
    /* border-top: 1px solid #dbdbdb; */
    /* border-bottom: 1px solid #dbdbdb; */
    filter: grayscale(100%) contrast(110%) saturate(0%);
    /* opacity: 0.5; */
    img + img {
        margin-left: 1rem;
    }
`

const CTA = styled.div`

    display: flex;
    display: none;

    align-items: center;

    /* text-decoration: none; */
    /* color: white; */
    padding: 12px 0px;
    /* width: 280px; */
    /* font-size: 14px; */
    /* font-weight: 600; */
    /* border-radius: 4px; */
    /* text-transform: uppercase; */
    /* letter-spacing: 2px; */

    & > div {
        /* background: ${props => props.app == 'teams' ? '#5059C9' : '#0078D4'}; */
        /* border-top: 2px solid ${props => props.app == 'teams' ? '#7B83EB' : '#28A8EA'}; */
    }


    img {
        height: 24px;
    }
    a + a {
        margin-left: 12px;
    }

`

const ProgressBar = styled.div`
    height: 6px;
    border-radius: 6px;
    background: #C1E8E3;
    overflow: hidden;
    width: 100%;
    & > div {
        background: #21A794;
        height: 100%;
        transition: all 0.33s;
        width: ${props => `${(props.progress/props.total)*100}%`}
    }

`


const Description = styled.div`
    padding: 0rem 1rem;
    p {
        &:hover {
            background: rgb(2, 115, 245, 0.05);
            background: #ced4da;
            text-decoration: none;
        }
        a {
            color: #21A794;
            text-decoration: none;
            font-weight: 600;
        }
        border-radius: 6px;
        cursor: pointer;
        font-size: 14px;
        line-height: 1.5;
        margin: 1rem 0rem;
        padding: 0.33rem 1rem;
    }
    background: white;
    border-top: 1px solid #dbdbdb;
    background: #fafafa;
`

const Intros = () => {
    const { user, isAuthenticated } = useAuth();
    const [currentUser, setCurrentUser] = useState(null);
    const [modelAccess, setModelAccess] = useState(null);
    const [ dataLoaded, setDataLoaded ] = useState(false);
    const {day, days, time, times, handleDayChange, handleTimeChange} = useContext(TimeSelectorContext);
    const { trackedLayersAlt, trackLayersAlt, trackedLayers, trackLayers, selectedModel, selectModel } = useContext(MapLayersContext);
    // const {loading} = useContext(LoadingContext);
    const {menu, toggleMenu} = useContext(AppContext);
    const [team, setTeam] = useState([]);
    const [selectedMember, selectMember] = useState(null);
    const [progress, setProgress] = useState([]);
    const [copied, copiedState] = useState(false);
    const [show, setShow] = useState(false);
    const delay = 1;
    const [selectedTime, selectTime] = useState(new Date);
    const [purchaseToggle, setPurchaseToggle] = useState(false);
    const [ products, setProducts ] = useState([]);

    useEffect (() => {
        // setShow(show)
        let timer1 = setTimeout(() => {
            setShow(false);
            copiedState(false);
        }, delay * 1000);
        // this will clear Timeout
        // when component unmount like in willComponentUnmount
        // and show will not change to true
        return () => {
          clearTimeout(timer1);
        };
    }, [copied]);

    // useEffect (() => {
    //     // fix for mobile
    //     setTimeout(() => {
    //         toggleMenu(true)
    //     }, 1000);
    // }, []);

      
    useEffect(()=>{
        setTimeout(() => {
            if (user && modelAccess) {
                document.getElementById('scroller').scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }, 333)
    }, [selectedModel, user])

    const setCurrentUserFromDb = async () => {
        if (user && isAuthenticated) {
            getDocument('users', user.uid).then(async (response) => {
                if (response.favoriteModel) {
                    selectModel(response.favoriteModel);
                } else {
                    selectModel('gomofs');
                }
                getModelAccess().then(async (res) => {
                    setCurrentUser(response);
                    setModelAccess(res);
                    setDataLoaded(true);
                }).catch((e) => {
                    console.log('error: ', e);
                });
                // console.log('userDataInShell: ', response);
            }).catch((err) => {
                console.log('error: ', err);
                selectModel('gomofs');
                getModelAccess().then(async (res) => {
                    //TODO fix this issue. After signup it isn't pulling in the  user from firestore
                    setCurrentUser({
                        models: [],
                        premium: false,
                    });
                    setModelAccess(res);
                    setDataLoaded(true);
                }).catch((e) => {
                    console.log('error: ', e);
                });
            });
        } else {
            setCurrentUser(null);
        }
    }

    useEffect(() => {
        setCurrentUserFromDb();
    }, [user])

    useEffect(() => {
        setCurrentUserFromDb();
    }, []);

    useEffect(() => {
        getProducts().then(res => {
            setProducts(res);
        })
    }, [])


    let x = new Date;
    
    return (
        <Wrapper menu={menu}>
            <ReactVelocity/>
            <Column menu={menu}>
                {/* <ul id="scroller">
                </ul> */}
                {user && modelAccess && (
                    <>
                    <LayerToggle setPurchaseToggle={setPurchaseToggle} purchaseToggle={purchaseToggle} authUser={user} user={currentUser} modelAccess={modelAccess} />
                    <Description>
                        <p
                            onClick={() => {
                                window.open('https://www.current-lab.com/contact')
                            }}
                        >
                            <a target="_blank" href="https://www.current-lab.com/contact">Share Feedback</a>
                        </p>
                    </Description>
                    </>
                )}
            </Column>
            <Main menu={menu}>
                <Nav>
                    <div>
                        <div>
                            <div onClick={()=>{toggleMenu(!menu)}}>
                                {/* {menu ? <MenuClose/> : <MenuOpen/>} */}
                                <MenuOpen/>
                            </div>
                        </div>
                        <div>
                            <a href='https://www.current-lab.com'><img src={Logo} alt="" /></a>
                        </div>
                        <div>
                            {/* <a href="mailto:info@current-lab.com?subject=Feedback for Nautilus Santa Monica Basin demo&body=Name:%0A%0AFeedback type (Bug report, Feature request, Question, Review):%0A%0ADescription:%0A%0AOverall rating so far (1-5 stars):"><Question/></a> */}
                            <User currentUser={currentUser}/>
                            {/* <a href="https://www.current-lab.com/contact"><Question/></a> */}
                        </div>
                    </div>
                    {/* {loading && <Loader/>} */}
                </Nav>
                <Content menu={menu} key={team[selectedMember]?._id}>
                    <div>
                        <Reticule />
                        {dataLoaded ? (
                        <Map menu={menu} selectedTime={time} currentUser={currentUser} key={currentUser ? currentUser.toString() : 'null'} modelAccess={modelAccess}/>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '80vh',
                                }}
                            >
                                <Spinner
                                    size='xl'
                                    color='gray'
                                    thickness='4px'
                                    speed='0.45s'
                                />
                            </Box>
                        )
                        }
                    </div>
                </Content>
            </Main>
            <Modal />
            <Upgraded modelAccess={modelAccess}/>
            {purchaseToggle && <ModelPurchase products={products} currentUser={currentUser} toggle={setPurchaseToggle} />}
        </Wrapper>
    )
}

export default Intros;
