import { getAuth, signOut } from 'firebase/auth'
import { useAuth } from './hooks/useAuth'
import { useState } from 'react'

import SignOut from './icons/SignOut'
import Info from './icons/Info'
import Userdetails from './components/modal/ModelPurchase.js'

import styled from 'styled-components'

const Wrapper = styled.div`
  div {
    display: flex;
    // min-width: 200px;
    justify-content: flex-start;
    // align-items: center;
    flex-direction: row;
    // margin-right: 3rem;
  }
  button {
    all: unset;
    cursor: pointer;
    // padding-right: 4rem;
    /* border: 1px solid gray; */
    /* border-radius: 6px; */
    /* padding: 0.33rem 0.66rem; */
    /* font-size: 14px; */
    /* color: gray; */
  }
  p {
    padding: 1rem;
    color: gray;
  }
`

const PopOver = styled.div`
  background: rgba(0, 0, 0, 0.33);
}`

const User = (props) => {
  const { user, isAuthenticated } = useAuth()
  const [show, setShow] = useState(false)
  const { currentUser } = props

  return isAuthenticated && (
    <Wrapper>
      <div>
        {/* <button onClick={() => {
          setShow(!show)
        }}>
          <Info/>
        </button> */}
        {/* <p>
          {currentUser?.email || ''}
        </p> */}
        <button onClick={() => {
          signOut(getAuth());
          // window.location.reload();
        }}>
          <SignOut/
        ></button>
        {show && 
          <Userdetails currentUser={currentUser} />
        }
      </div>
    </Wrapper>
  )
}

export default User