import React, { useState, useEffect } from 'react';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {

    const x = window.matchMedia("(max-width: 768px)");

    const [menu, toggleMenu] = useState(!x.matches);
    const [isMobile, setIsMobile] = useState(x.matches);

    const dynamicDrawerToggle = (x) => {
        if (x.matches) {
            toggleMenu(true);
            setIsMobile(true);
        } else {
            toggleMenu(true);
            setIsMobile(false);
        }
    }

    console.log(isMobile)

    useEffect(()=>{
        x.addEventListener("change", dynamicDrawerToggle);
    }, [])

    useEffect(()=>{
    }, [isMobile])

    return (
        <AppContext.Provider value={{menu, toggleMenu, isMobile}}>
            {children}
        </AppContext.Provider>
    )
}

export { AppProvider, AppContext };