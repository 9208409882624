import React, { useContext, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import MarkerEdit from './icons/MarkerEdit'
import MarkerAdd from './icons/MarkerAdd'
import MarkerClear from './icons/MarkerClear'
import Close from './icons/Close'

const appear = keyframes`
    from {
        /* opacity: 0; */
        height: 0;
        flex: .00001;
    }

    to {
        /* opacity: 1; */
        height: 48px;
        flex: 1;
    }
`;

const fadein = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;


const Wrapper = styled.div`

    position: absolute;
    z-index: 3;
    top: 10px;
    left: 10px;
    border-radius: 6px;
    & > div {
        display: grid;
        grid-template-columns: 48px 1fr;
        align-items: center;
        justify-content: space-between;
        /* border: 1px solid red; */

        &:nth-child(2),
        &:last-child:not(:only-child) {
            animation: ${appear} 2s;
            overflow: hidden;
        }

        &:first-child > div {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }
        &:last-child > div {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
        &:nth-child(2) {
            svg {
                * {
                    stroke: ${props => props.enabled ? 'white' : 'black'};
                }
            }
            div {
                background: ${props => props.enabled ? '#0273f5' : 'white'};
            }
        }
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            background: white;
            min-height: 0;
            overflow: hidden;
            white-space: nowrap;

            &:hover + p {
                opacity: 1;
            }
            
            svg {
                animation: ${fadein} .33s;
                animation-delay: .1s;
                animation-fill-mode: forwards;
                opacity: 0;
            }

        }
        
        p {
            opacity: 0;
            color: white;
            margin: 0;
            padding: 0 1rem;
            font-weight: 500;
        }
    }

`

export const MarkerControl = ({clearMarkers}) => {
    
    const [enabled, setEnabled] = useState(false);
    const [toggled, setToggled] = useState(false);
    
    const _handleControl = () => {
        setToggled(!toggled);
    }

    const _handleAdd = () => {
        setEnabled(!enabled);
    }

    const _handleClear = () => {
        clearMarkers();
        setToggled(!toggled);
        setEnabled(false);
    }

    return (
        <Wrapper enabled={enabled}>
            <div key="menuitem1" onClick={_handleControl}>
            {toggled ? (
                <>
                    <div><Close/></div>
                    <p>Close</p>
                </>
            ) : (
                <>
                    <div><MarkerEdit/></div>
                    <p>Edit Markers</p>
                </>
            )}
            </div>
            {toggled ? <div key="menuitem2" onClick={_handleAdd}>
                <div><MarkerAdd/></div>
                <p>Add Markers</p>
            </div> : null}
            {toggled ? <div key="menuitem3" onClick={_handleClear}>
                <div><MarkerClear/></div>
                <p>Clear Markers</p>
            </div> : null}
        </Wrapper>
    )
}