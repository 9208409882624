import { getFirestore, collection, addDoc, doc, getDoc, setDoc, getDocs, query, where } from "firebase/firestore"

const db = getFirestore();


export const getProducts = async () => {
  // get all docs in firebase collection
  console.log("getProducts")
  const docs = collection(db, "products");
  const docSnap = await getDocs(docs);
  if (docSnap.empty) {
    console.log("No model found");
  } else {
    const productData = await docSnap.docs.map(doc => doc.data());
    const activePackages = productData.filter(product => product.active && product.stripe_metadata_private !== 'true')
    return activePackages || productData;
  }
}