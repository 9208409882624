import { useState, useEffect, useContext, createContext, useReducer } from 'react'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signOut } from '@firebase/auth'
import { getFirestore, collection, doc, getDoc, setDoc, getDocs, query, where } from "firebase/firestore"
import posthog from 'posthog-js';
import "firebase/firestore";
import { MapLayersContext } from './MapLayers';

const firebaseConfig = {
    apiKey: "AIzaSyC7YE_shsr0fCbb7qrBzVFi8Ir4tSWFXyM",
    authDomain: "current-map-app.firebaseapp.com",
    projectId: "current-map-app",
    storageBucket: "current-map-app.appspot.com",
    messagingSenderId: "536791482107",
    appId: "1:536791482107:web:521918e99c3d705358fa5e",
    measurementId: "G-WX901ZTWJ5"
  };

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export default db;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

export const AuthContext = createContext(initialState);

export const getDocument = async (coll, id) =>{
  const snap = await getDoc(doc(db, coll, id))
  if (snap.exists())
    return snap.data()
  else
    return Promise.reject(Error(`No such document: ${coll}.${id}`))
}

export const AuthContextProvider = props => {
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [state, dispatch] = useReducer(reducer, initialState);
  

  useEffect(()=>{
    if (user) {
      posthog.identify(
        user?.email || user?.id, // distinct_id, required
        { providedData: user?.UprovidedData }, // $set, optional
        { email: user?.email }, // $set, optional
        { accountCreated: user?.metadata.creationTime } // $set_once, optional
    );
    }
  }, [user])

  const logout = async () => {
    await signOut();
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError)
    return () => unsubscribe()
  }, [])
  return (
    <AuthContext.Provider
      value={{
        ...state,
        user,
        isAuthenticated: user !== null,
        error
      }}
      {...props}
    >
      {props.children}
    </AuthContext.Provider>
  )
}