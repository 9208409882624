function roundTo(num, interval) {
    return Math.floor(num / interval) * interval;
}

function roundHours(date, interval) {
    var newDate = new Date(date);
    var h = newDate.getUTCHours() + newDate.getUTCMinutes() / 60 + newDate.getUTCSeconds() / 3600 + newDate.getUTCMilliseconds() / 3600000;
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    newDate.setUTCHours(roundTo(h, interval));
    return newDate;
}

export {roundTo, roundHours }