import React from 'react';

function GalleryLayout(props) {
	const title = props.title || "gallery layout";

	return (
		<svg height="16" width="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="#454545">
		<rect height="2" width="2" fill="#454545" x="1" y="1"/>
		<rect height="2" width="2" fill="#454545" x="5" y="1"/>
		<rect height="2" width="2" fill="#454545" x="9" y="1"/>
		<rect height="2" width="2" fill="#454545" x="13" y="1"/>
		<rect height="2" width="2" fill="#454545" x="1" y="5"/>
		<rect height="2" width="2" fill="#454545" x="5" y="5"/>
		<rect height="2" width="2" fill="#454545" x="9" y="5"/>
		<rect height="2" width="2" fill="#454545" x="13" y="5"/>
		<rect height="2" width="2" fill="#454545" x="1" y="9"/>
		<rect height="2" width="2" fill="#454545" x="5" y="9"/>
		<rect height="2" width="2" fill="#454545" x="9" y="9"/>
		<rect height="2" width="2" fill="#454545" x="13" y="9"/>
		<rect height="2" width="2" fill="#454545" x="1" y="13"/>
		<rect height="2" width="2" fill="#454545" x="5" y="13"/>
		<rect height="2" width="2" fill="#454545" x="9" y="13"/>
		<rect height="2" width="2" fill="#454545" x="13" y="13"/>
	</g>
</svg>
	);
};

export default GalleryLayout;