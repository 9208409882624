import React from 'react';

function MoveLayerDown(props) {
	const title = props.title || "move layer down";

	return (
		<svg height="12" width="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="#454545">
		<path d="M11,10H1a1,1,0,0,0,0,2H11a1,1,0,0,0,0-2Z" fill="#454545"/>
		<path d="M6.5,0h-1A.5.5,0,0,0,5,.5V3H2.5a.5.5,0,0,0-.376.829l3.5,4a.5.5,0,0,0,.752,0l3.5-4A.5.5,0,0,0,9.5,3H7V.5A.5.5,0,0,0,6.5,0Z"/>
	</g>
</svg>
	);
};

export default MoveLayerDown;