import { db } from "../contexts/Firebase";
import { updateDoc, doc } from "firebase/firestore"; 

export async function updateFavorite(uid, model) {
  // Create a new checkout session in the subcollection inside this users document
  console.log('updating fovorite')
  const washingtonRef = doc(db, 'users', uid);

    // Set the "capital" field of the city 'DC'
    await updateDoc(washingtonRef, {
      favoriteModel: model
  })
}