import React, { useState, useEffect } from 'react';
import _ from 'lodash'

const TimeSelectorContext = React.createContext();

Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
}

function isInTimeArray(array, value) {
    return !!array.find(item => {return item.getTime() == value.getTime()});
}

const TimeSelectorProvider = ({ children }) => {

    var coeff = 1000 * 60 * 60;
    const date = new Date;
    var options = { weekday: 'short', month: 'numeric', day: 'numeric' };
    
    const dates = Array.from({length: 72}, (_, i) => {
        return new Date(Math.floor(date.getTime() / coeff) * coeff).addHours(i)
    });
    
    const grouped = _.groupBy(dates, (v, i) => {
        return v.toLocaleDateString('en-US', options)
    });

    const [day, selectDay] = useState(dates[0].toLocaleDateString('en-US', options))
    const [days, selectDays] = useState(_.keys(grouped));
    const [times, selectTimes] = useState(grouped[day]);
    const [time, selectTime] = useState(times[0])
    
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);

    const handleDayChange = (event) => {
        selectDay(event.target.value);
    }

    const handleTimeChange = (event) => {
        selectTime(new Date(event.target.value));
    }

    const offsetTimeChange = (event, offset) => {
        
        selectTime(prevTime => {
            const nextTime = new Date(prevTime).addHours(offset);
            if (isInTimeArray(dates, nextTime)) {
                selectDay(nextTime.toLocaleDateString('en-US', options));
                return nextTime;
            } else {
                return prevTime
            }
        });
        
        // const nextTime = new Date(time).addHours(offset);
        // if (isInTimeArray(dates, nextTime)) {
        //     selectDay(nextTime.toLocaleDateString('en-US', options));
        //     selectTime(nextTime);
        // } else {
        //     alert('Inaccessible time')
        // }
    }

    function toggleAutoPlay() {
        setIsActive(!isActive);
    }

    function reset() {
        setSeconds(0);
        setIsActive(false);
    }

    // useEffect(() => {
    //     let interval = null;
    //     if (isActive) {
    //       interval = setInterval(() => {
    //         setSeconds(seconds => seconds + 1);
    //         offsetTimeChange(null, 1);
    //       }, 5000);
    //     } else if (!isActive && seconds !== 0) {
    //       clearInterval(interval);
    //     }
    //     return () => clearInterval(interval);
    // }, [isActive, seconds]);


    useEffect(()=>{
        selectTimes(grouped[day])
    }, [day])

    useEffect(()=>{
        selectTime(times[0])
    }, [times])

    useEffect(()=>{
        console.log(time)
    }, [time])

    return (
        <TimeSelectorContext.Provider value={{day, days, time, times, handleDayChange, handleTimeChange, offsetTimeChange, grouped, isActive, toggleAutoPlay}}>
            {children}
        </TimeSelectorContext.Provider>
    )
}

export { TimeSelectorProvider, TimeSelectorContext };