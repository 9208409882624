import L from 'leaflet';

export const MyLocation = L.Control.extend({
  options: {
    position: 'topleft',
    title: 'Fly to my location',
    maxZoom: 12
  },
  onAdd: function(map) {
    const name = 'my-location',
      container = L.DomUtil.create('div', name + ' leaflet-bar'),
      options = this.options;
    this._flyToMyLocationButton = this._createButton(options.title,name,container,this._flyToMyLocation);
    map.on('locationfound',this._handleOnLocationFound,this);
    return container;
  },
  onRemove: function(map){
    map.off('locationfound',this._handleOnLocationFound,this);
  },
  _flyToMyLocation: function() {
    this._map.locate({
      setView: true,
      maxZoom: this.options.maxZoom
    });
  },
  _handleOnLocationFound: function(event) {
    const {latlng,accuracy:radius} = event;
    const circle = L.circle(latlng,radius);

    circle.addTo(this._map);
  },
  _createButton: function(title,className,container,fn) {
    const link = L.DomUtil.create('a',className,container);
    link.href = '#';
    link.title = title;
    
    const icon = '<img style="position: absolute; top: 50%; left: 50%; transform: translate(-55%, -45%); height: 18px; width: 18px;" src="/icons/GPS.svg"/>';
    link.innerHTML = icon;

    link.setAttribute('role','button');
    link.setAttribute('aria-label',title);
    L.DomEvent.disableClickPropagation(link);
    L.DomEvent.on(link, 'click', L.DomEvent.stop);
    L.DomEvent.on(link, 'click', fn, this);
    L.DomEvent.on(link, 'click', this._refocusOnMap, this);

    return link
  }
});
