import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import _ from 'lodash';

const MapLayersContext = React.createContext();

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const MapLayersProvider = ({ children }) => {

    const [trackedLayers, trackLayers] = useState({});
    const [trackedLayersAlt, trackLayersAlt] = useState([]);
    const [selectedModel, selectModel] = useState();

    const query =  useQuery().get('m');

    useEffect(()=>{
        _.map(trackedLayersAlt, 'layers.model').includes(query) && selectModel(query)
    }, [trackedLayersAlt])

    return (
        <MapLayersContext.Provider value={{trackedLayersAlt, trackLayersAlt, trackedLayers, trackLayers, selectedModel, selectModel}}>
            {children}
        </MapLayersContext.Provider>
    )
}

export { MapLayersProvider, MapLayersContext };
