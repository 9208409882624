import React, { useContext } from 'react';
import styled from 'styled-components';

import Tabs, { Panel } from './Tabs'
import { TimeSelectorContext } from './contexts/TimeSelector';
import { AppContext } from './contexts/App';
import { MapInteractionContext } from './contexts/MapInteraction';
import { LoadingContext } from './contexts/Loading';

const OverlayWrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    
    z-index: 10;
    pointer-events: none; 
    display: grid;
    grid-template-rows: 1fr auto 24px;

    overflow: hidden;

    & > div:nth-child(1) {
        & > div {
            pointer-events: all;
        }
    }
    & > div:nth-child(2) {
        padding: 1rem;
        pointer-events: all;
        max-width: 400px;
        @media only screen and (max-width: 768px) {
            max-width: unset;
        }
    }
    & > div:nth-child(3) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        color: white;
        font-size: 11px;
        padding: 0rem 1rem;
        background: rgba(0, 0, 0, 0.75);
    }
    
`

export const Overlay = () => {

    const { time } = useContext(TimeSelectorContext);
    const { isMobile } = useContext(AppContext);
    const {loading} = useContext(LoadingContext);
    const {mapZoom} = useContext(MapInteractionContext);
    


    return (
        <OverlayWrapper>
                <div>
                </div>
                <div>
                    <Panel/>
                    <Tabs/>
                </div>
                <div>
                    <div key="one">{loading ? '⏳ Downloading Data...' : time.toString()}</div>
                    <div key="two">{mapZoom}</div>
                </div>
        </OverlayWrapper>
    );

}