import React, { useContext, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../hooks/useAuth'
import {
    BrowserRouter as Router,
    useParams
  } from "react-router-dom";
import { AppContext } from '../../contexts/App';

import { SignUp } from './SignUp';
import posthog from 'posthog-js';
import { Firestore } from 'firebase/firestore';
import db from '../../contexts/Firebase';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { Progress } from '@chakra-ui/react'
import { getAllJSDocTagsOfKind } from 'typescript';
import { Button } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';


const Wrapper = styled.div`
    background: rgba(0, 0, 0, 0.33);
    backdrop-filter: blur(6px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    min-height: calc((var(--vh, 1vh)*100));
    max-height: calc((var(--vh, 1vh)*100));
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
        box-sizing: border-box;
        border-radius: 6px;
        background: white;
        padding: 3rem;
        width: 500px;
        display: flex;
        flex-direction: column;
        align-items: flex;
        justify-content: center;

        h1 {
            font-sizeL 4rem;
        }

        @media only screen and (max-width: 768px) {
            width: 100%;
            padding: 1rem;
            height: 100vh;
            border-radius: 0px;
        }
    }
`

const UpgradeButton = styled.div`
    cursor: pointer;
    height: 3rem;
    width: 50%;
    align-content: center;
    padding: 1rem;
    border-radius: 4px;
    background: #ced4da;
    h3 {
        font-size: 16px;
        margin: auto;
        font-weight: 800;
        color: #495057;
        text-align: center;
    }
    &:hover {
        background: rgb(2, 115, 245, 0.05);
        background: #a5d8ff;
    }
`

const Modal = (props) => {
    const { modelAccess } = props;
    const { user, isAuthenticated } = useAuth()
    const { showModal, toggleModal } = useContext(AppContext)
    // const { upgrade } = useParams();
    const search = useLocation().search;
    const model = new URLSearchParams(search).get('model');
    const [upgradedModels, setUpgradedModels] = useState([]);
    const [ loading, setLoading] = useState(true)
    

    useEffect(() => {
        if (model) {
            posthog.capture('upgrade', {
                'upgrade': model
            })
        }
        getModels();
    }, [model])

    useEffect(() => {
        // console.log(name);
        if (model) {
            setLoading(true);
        }
    }, [model]);

    const getModels = async () => {
        const q = query(collection(db, 'products'), where ('priceID', '==', model));
        const models = await getDocs(q);
        models.forEach((doc) => {
            setUpgradedModels(doc.data().name);
        });
    }

    useEffect(() => {
        posthog.capture('Purchase', {
            'Package': upgradedModels,
        })
        setTimeout(() => {
            setLoading(false)
        }, [8000])
    }, [])

    // if current param is not 'upgrade', return null
    if (model === null || model === undefined) {
        return null
    } else {
        if (upgradedModels.length !== 0) {
            return (
                <Wrapper>
                    <div>
                        <h1>
                            <strong>
                                Upgraded!
                            </strong>
                        </h1>
    
                        <p>
                            You now have access to the following premium packages:
                        </p>
                        <b>
                            {upgradedModels}
                        </b>
                        <br/>
                        <p>
                            Your new models are downloading!
                        </p>
                        <br/>
                        {!loading ? (
                            <Button
                                colorScheme='blue'
                                onClick={() => {
                                    window.location.href = '/'
                                }}
                            >
                                <h3>
                                    Load new models
                                </h3>
                            </Button>
                        ) : (
                            <Progress
                                colorScheme='blue'
                                size='xs'
                                isIndeterminate
                                // value={5000}
                                sx={{
                                    mt: 5
                                }}
                            />
                        )}
                    </div>
                </Wrapper>
            )
        } else {
            return null;
        }
    }   
}

export default Modal;