import { useCallback, useState, useContext } from 'react'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth'
import { AppContext } from '../../contexts/App';

import styled from 'styled-components'
import Logo from '../../img/current_lab.svg'
import posthog from 'posthog-js';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	& > a {
		/* background: red; */
	}
	img {
		width: 128px;
	}
	form {
		display: grid;
		grid-gap: 0.66rem;
		width: 100%;
		div {
			display: inline-flex;
			flex-flow: column-reverse;
			/* align-items: flex-start */
			label {
				transition: opacity 0.33s;
				opacity: 0;
				height: 0;
			}

			input:not(:placeholder-shown) + label {
				opacity: 1;
				height: auto;
			}
		}
		label {
			padding-left: 0.66rem;
			font-size: 12px;
			transform: translateY(50%);
			background: white;
			padding: 0.33rem;
			margin-left: 0.33rem;
			align-self: flex-start;
			/* margin-bottom: 0.33rem; */
		}
		fieldset {
			all: unset;
			display: grid;
			grid-gap: 2rem;
			/* grid-template-columns: minmax(auto, 50%) minmax(auto, 50%); */
			grid-template-columns: repeat(2, calc(50% - 1rem));
		}
		input {
			all: unset;
			font-size: 14px;
			border: 1px solid gray;
			padding: 0.8rem 0.66rem;
			/* background: #f5f5f5; */
			border-radius: 6px;
			width: 100%;
    		box-sizing: border-box;
		}
		input:-webkit-autofill {
			-webkit-box-shadow:0 0 0 50px rgba(2, 114, 242, 0.01) inset; /* Change the color to your own background color */
			-webkit-text-fill-color: #333;
		}
		select {
			all: unset;
			padding: 0.33rem 0rem;
			border-bottom: 1px solid gray;
		}
		button {
			all: unset;
			background: #0264d4;
			padding: 1rem 0.66rem;
			cursor: pointer;
			border-radius: 6px;
			color: white;
			text-align: center;
			margin-top: 0.66rem;
		}
	}
	p {
		font-size: 14px;
		text-align: center;
		b {
			cursor: pointer;
		}
	}

`

const SignUpForm = ({newUser, onSubmit}) => {
	
	return (
		<form
			onSubmit={onSubmit}
		>
			<div>
				<input id="email" name="email" placeholder="Email" type="email" />
				<label htmlFor="email">Email Address</label>
			</div>
			<div>
				<input id="password" name="password" placeholder="Password" type="password" />
				<label htmlFor="password">Password</label>
			</div>
			{newUser &&
			<>
				<div>
					<input id="confirm-password" name="confirm-password" placeholder="Confirm Password" type="password" />
					<label htmlFor="password">Confirm Password</label>
				</div>
				<div>
					<span>
						{'By signing up, you agree to the '}
						<a href="https://www.current-lab.com/terms"><b>Terms</b></a>
					</span>
				</div>
			</>}
			<button
				type="submit"
				onClick={
					posthog.capture('Create Account Clicked')
				}
			>
				{newUser ? 'Create Account' : 'Sign In'}
			</button>
		</form>
  	)
}

export const SignUp = () => {

const [newUser, toggleNewUser] = useState(true);
const [showHelper, toggleHelper] = useState(false);

const signIn = useCallback(async e => {
    
	e.preventDefault()
    const { email, password } = e.target.elements
    const auth = getAuth()

	try {
    await signInWithEmailAndPassword(auth, email.value, password.value)
  } catch (e) {
    console.log(e.message)
		alert();
  }

}, [])

const signUp = useCallback(async e => {
    
	e.preventDefault()
    const { email, password } = e.target.elements;
		const { firstName, lastName } = e.target.elements;
		console.log(e.target.elements);
    const auth = getAuth();
    try {
      await createUserWithEmailAndPassword(auth, email.value, password.value).then(()=>{
				// toggleModal(false);
				console.log('user created');
			})
    } catch (e) {
		if (e.code == 'auth/email-already-in-use') {
			toggleHelper(true);
			toggleNewUser(false);
		} else {
			toggleHelper(false);
		}
		console.log(e.code)
    }

}, [])

return (
    <Wrapper>
		<a href='https://www.current-lab.com'><img src={Logo} alt="" /></a>
      	<h1>{newUser ? 'Create your account' : 'Sign in'}</h1>
      	{showHelper ? <p>Looks like you already have an account.</p> : null}
	  	<SignUpForm newUser={newUser} onSubmit={newUser ? signUp : signIn}/>
		<p>
			<span>{newUser ? 'Already have an account?' : 'Don\'t have a Current Map Account?'}</span>
			&nbsp;
			<b onClick={()=> {
				toggleHelper(false); toggleNewUser(!newUser)}}>{!newUser ? 'Create one!' : 'Click here to Sign In.'
				}
			</b>
		</p>
    </Wrapper>
  )
}