import { useState, useEffect, useContext } from 'react';
import { MapInteractionContext } from '../contexts/MapInteraction';
import { TimeSelectorContext } from '../contexts/TimeSelector'
import { roundHours } from '../util/Rounding'


import intersect from '@turf/intersect';
import area from '@turf/area';

const createBoundaryFeature = (bounds) => {
    return {
        "type": "Feature",
        "properties": {
            "bounds": bounds
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [[
                [bounds.lo1, bounds.la2],
                [bounds.lo2, bounds.la2],
                [bounds.lo2, bounds.la1],
                [bounds.lo1, bounds.la1],
                [bounds.lo1, bounds.la2]
            ]]
        }
    }
}

export const modelArea = (model) => {
    return area(createBoundaryFeature(model.layers.bounds))
}

export const boundsArea = (bounds) => {
    return area(createBoundaryFeature(bounds))
}

export const intersectsMap = (model, mapBounds) => {
    return intersect(createBoundaryFeature(model.layers.bounds), createBoundaryFeature(mapBounds))
}

export const intersectionArea = (model, mapBounds) => {
    return  intersectsMap(model, mapBounds) ? area(intersectsMap(model, mapBounds)) / area(createBoundaryFeature(mapBounds)) : 0;
}

export const intersectionThreshold = (model, mapBounds, threshold, direction) => {
    if (direction) {
        return  intersectionArea(model, mapBounds) > threshold;
    } else {
        return  intersectionArea(model, mapBounds) < threshold;
    }
}

export default function useVisible(models) {
    
    const { mapBounds } = useContext(MapInteractionContext);
    const { time } = useContext(TimeSelectorContext);

    const [partitionedModels, partitionModels] = useState({ withinView: [], outOfView: [], expired: [] });

    useEffect(() => {
        const withinView = [];
        const outOfView = [];
        const expired = [];
        const premium = [];
        for (var i = 0;  i < models.length ; i++) {

            let t = time;
            if (models[i].timestep == '3h') {
                t = roundHours(time, 3);
            }

            const screenIntersection = intersectsMap(models[i], mapBounds);
            
            if (`${t.toISOString().slice(0, 13).replace("T", "_")}.00` > models[i].forecastLimit) {
                expired.push(models[i]);
            } else if (screenIntersection != null) {
                withinView.push(models[i]);
            } else {
                outOfView.push(models[i])
            }
        }
        partitionModels({withinView, outOfView, expired, premium})
    }, [ mapBounds ]);

  return partitionedModels;
}
