import React, {useState, useEffect, useContext} from "react";
import { render } from "react-dom";
import { createGlobalStyle } from 'styled-components';
import { TimeSelectorProvider } from './contexts/TimeSelector';
import { MapLayersProvider } from './contexts/MapLayers';
import { WindSpeedProvider } from './contexts/WindSpeed';
import { MouseContextProvider } from "./contexts/Mouse";
import { TabProvider } from "./Tabs";
import { LoadingProvider } from "./contexts/Loading";
import { MapInteractionProvider } from "./contexts/MapInteraction";
import { AppProvider } from './contexts/App';
import { AuthContextProvider } from "./contexts/Firebase";
import { ChakraProvider } from '@chakra-ui/react'



import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

import Intros from './shell'

import posthog from 'posthog-js'

posthog.init('phc_uIYHekcwG3bHpLjMsdN9N8qjAbCcGZnbyYGYz4GyF3P', { api_host: 'https://app.posthog.com' })

const GlobalStyles = createGlobalStyle`
    
    * {
        box-sizing: border-box;
        /* overflow-x: hidden; */
    }
    
    /* width */
    ::-webkit-scrollbar {
        width: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.6);
        background-clip: padding-box;
        border-radius: 50px;
        border: 6px solid rgba(0, 0, 0, 0);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        /* background: rgba(255, 255, 255, 1); */
        /* background-clip: padding-box; */
    }
    
    body {
        /* text-shadow: 1px 0px 0px rgba(50,50,50,0.2),  0px 1px 0px rgba(50,50,50,0.15); */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: overlay;
        font-family: 'Inter', sans-serif;
        /* font-family: 'Atkinson Hyperlegible', sans-serif; */
    }

    html {
      overflow: hidden;
    }
    
    :root {
        --vh: ${props => props.height * 0.01}px;
    }
`

const App = () => {

    const [height, setHeight] = useState(window.innerHeight);

    useEffect(()=>{
        window.addEventListener('resize', () => {
            setHeight(window.innerHeight);
        });
    }, [])

    return (
        <React.StrictMode>
            <Router>
                <AuthContextProvider>
                    <AppProvider>
                        <ChakraProvider>
                            <TimeSelectorProvider>
                                <WindSpeedProvider>
                                    <MapLayersProvider>
                                        <MouseContextProvider>
                                            <TabProvider>
                                                <LoadingProvider>
                                                    <MapInteractionProvider>
                                                        <Switch>
                                                            <Route path="/:upgrade" component={Intros}/>
                                                            <Route path="/" component={Intros}/>
                                                        </Switch>
                                                        <GlobalStyles height={height}/>
                                                    </MapInteractionProvider>
                                                </LoadingProvider>
                                            </TabProvider>
                                        </MouseContextProvider>
                                    </MapLayersProvider>
                                </WindSpeedProvider>
                            </TimeSelectorProvider>
                    </ChakraProvider>
                    </AppProvider>
                </AuthContextProvider>
            </Router>
        </React.StrictMode>
    )
}

render(<App />, document.getElementById("root"));