import React from 'react';

function Gantt(props) {
	const title = props.title || "gantt";

	return (
		<svg height="12" width="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="#454545">
		<rect height="2" width="4" fill="#454545" y="1"/>
		<rect height="2" width="8" x="2" y="5"/>
		<rect height="2" width="4" fill="#454545" x="8" y="9"/>
	</g>
</svg>
	);
};

export default Gantt;