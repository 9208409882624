import React from 'react';

function ButtonStop(props) {
	const title = props.title || "button stop";

	return (
		<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="#454545">
		<path d="M22,1H2C1.447,1,1,1.447,1,2v20c0,0.553,0.447,1,1,1h20c0.553,0,1-0.447,1-1V2C23,1.447,22.553,1,22,1z" fill="#454545"/>
	</g>
</svg>
	);
};

export default ButtonStop;