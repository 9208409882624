import React from 'react';

function IRemove(props) {
	const title = props.title || "i remove";

	return (
		<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="#454545" stroke="#454545" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
		<line fill="none" stroke="#454545" x1="16" x2="8" y1="8" y2="16"/>
		<line fill="none" stroke="#454545" x1="16" x2="8" y1="16" y2="8"/>
	</g>
</svg>
	);
};

export default IRemove;